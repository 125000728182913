<template>
  <header class="login-header">
    <div class="container">
      <h1 class="logo" style="margin-top: 20px">忘记密码</h1>
    </div>
    <!-- 第一步 -->
    <div
      v-if="stepReg === 1"
      class="container"
      style="background: #f5f7fa; height: 50px; margin-top: 15px"
    >
      <h3 class="logo">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img src="../../../../static/icons/step1.png" alt="" />&nbsp;&nbsp;
        输入手机号
      </h3>
      <img src="../../../../static/icons/right.png" alt="" />
      <h3 class="logo">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <img src="../../../../static/icons/step2.png" alt="" />&nbsp;&nbsp;
        <span class="unselectText">修改密码</span>
      </h3>
      <img src="../../../../static/icons/right.png" alt="" />
      <h3 class="logo">

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img src="../../../../static/icons/step3.png" alt="" />&nbsp;&nbsp;
				<span class="unselectText">修改完成</span>
      </h3>
      <img src="" alt="" />
    </div>
    <!-- 第二步 -->
    <div
      v-if="stepReg === 2"
      class="container"
      style="background: #f5f7fa; height: 50px; margin-top: 15px"
    >
      <h3 class="logo" @click="toStep(1)">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img src="../../../../static/icons/suc.png" alt="" />&nbsp;&nbsp;
        <span style="color: #d12b23"> 输入手机号</span>
      </h3>
      <img src="../../../../static/icons/right.png" alt="" />
      <h3 class="logo">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img src="../../../../static/icons/step2.png" alt="" />&nbsp;&nbsp;
        修改密码
      </h3>
      <img src="../../../../static/icons/right.png" alt="" />
      <h3 class="logo">
        <!-- <RouterLink to="/">齐鲁大宗</RouterLink> -->
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img src="../../../../static/icons/step3.png" alt="" />&nbsp;&nbsp;
        	<span class="unselectText">修改完成</span>
      </h3>
      <img src="" alt="" />
    </div>
    <!-- 第三步 -->
    <div
      v-else-if="stepReg === 3"
      class="container"
      style="background: #f5f7fa; height: 50px; margin-top: 15px"
    >
      <h3 class="logo" @click="toStep(1)">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img src="../../../../static/icons/suc.png" alt="" />&nbsp;&nbsp;
        <span style="color: #d12b23"> 输入手机号</span>
      </h3>
      <img src="../../../../static/icons/right.png" alt="" />
      <h3 class="logo" @click="toStep(2)">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <img src="../../../../static/icons/suc.png" alt="" />&nbsp;&nbsp;
        <span style="color: #d12b23"> 修改密码</span>
      </h3>
      <img src="../../../../static/icons/right.png" alt="" />
      <h3 class="logo">
        <!-- <RouterLink to="/">齐鲁大宗</RouterLink> -->
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img src="../../../../static/icons/step3.png" alt="" />&nbsp;&nbsp;
        修改完成
      </h3>
      <img src="" alt="" />
    </div>
  </header>
</template>

<script>
export default {
  name: 'LoginHeader',
  props: {
    step: {},
  },
  data () {
    return {
      stepReg: 1,
    }
  },
  mounted () {
    this.stepReg = this.step
  },
  methods: {
    toStep (val) {
      this.$emit('callbackStep', val)
    },
  },
  watch: {
    step (val) {
      // 切换step 步骤
      this.stepReg = val
    },
  },
}
</script>

<style scoped lang="less">
.login-header {
  background: #fff;

  // border-bottom: 1px solid #e4e4e4;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    width: 200px;
    font-weight: bold;
		.unselectText{
			color: #999999;
		}
  }
  .step {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sub {
    flex: 1;
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 50px;
    margin-left: 20px;
  }
  .entry {
    width: 120px;
    margin-bottom: 38px;
    font-size: 16px;
    i {
      font-size: 14px;
      color: @xtxColor;
      letter-spacing: -5px;
    }
  }
  .container-step {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
</style>
