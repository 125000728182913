<template>
  <Form
    ref="formCustom"
    :model="formCustom"
    :rules="ruleCustom"
    :label-width="80"
  >
    <FormItem label="用户名" prop="userName">
      <Input
        v-model="formCustom.userName"
        maxlength="16"
        placeholder="请输入用户名"
      ></Input>
    </FormItem>
    <FormItem label="新密码" prop="passwd">
      <Input
        v-model="formCustom.passwd"
        type="password"
        placeholder="请输入新密码"
      ></Input>
    </FormItem>
    <FormItem>
      <Button type="primary" class="btn" @click="handleSubmit('formCustom')">
        确认修改
      </Button>
    </FormItem>
  </Form>
</template>
<script>
import { ref } from 'vue'
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'
import Message from '@/components/library/Message'
export default {
  props: {
    // 父组件传递过来的参数
    registerData: {},
  },

  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码！'))
      } else {
        callback()
      }
    }

    return {
      tel: '',
      formCustom: {
        passwd: '',
        passwdCheck: '',
        userName: '',
      },
      ruleCustom: {
        passwd: [{ validator: validatePass, trigger: 'blur' }],
        userName: [
          {
            min: 4,
            max: 16,
            message: '用户名长度应大于等于4个字符!',
            trigger: 'blur',
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入用户名'))
              } else if (/[\u4E00-\u9FA5]/g.test(value)) {
                callback(new Error('不能输入汉字'))
              } else {
                callback()
              }
            },
          },
          {
            type: 'string',
            message: '请输入不包含空格的字符',
            trigger: 'blur',
            transform (value) {
              if (value && value.indexOf(' ') === -1) {
                return value
              } else {
                return false
              }
            },
          },
        ],
      },
    }
  },
  created () {
    this.tel = localStorage.getItem('iphone')
  },
  mounted () {},
  methods: {
    handleSubmit (name) {
      const encryptor = new JSEncrypt()
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch('PublicKey')
            .then((response) => {
              if (response.returnCode === '1') {
                encryptor.setPublicKey(response.result) // 设置公钥
                // 修改密码
                const postData = ref({
                  username: this.formCustom.userName,
                  password: encryptor.encrypt(this.formCustom.passwd),
                  tel: this.tel,
                })

                this.$store
                  .dispatch('ResetPassword', postData.value)
                  .then((response) => {
                    if (response.returnCode === '1') {
                      this.$emit('stepEvent', {
                        num: 3,
                        userName: this.formCustom.userName,
                        passwd: this.formCustom.passwd,
                      })
                      this.$Message.success('修改成功!')
                    } else {
                      this.$Message.error(response.result)
                    }
                  })
                  .catch(() => {})
              } else {
                Message({ type: 'error', text: '修改超时' })
              }
            })
            .catch(() => {})
        } else {
          this.$Message.error('请填写正确的信息')
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .ivu-input {
  padding-left: 8px;
  border: 1px solid #dcdfe6;
  height: 36px;
  line-height: 36px;
  width: 100%;
}
.btn {
  display: block;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  background: @xtxColor;
  border: none;
  &.disabled {
    background: #cfcdcd;
  }
}
</style>
