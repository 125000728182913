<template>
  <div class="container">
    <img src="../../../../static/icons/success.png" alt="" />
    <span style="color: #606266; margin-top: 20px"> 密码修改成功</span>
    <p style="margin-top: 10px">
      {{ time }}
			 <span>s后自动跳转至登录页, 或点击</span>
      <RouterLink to="/login">
        <a>手动跳转</a>
      </RouterLink>
    </p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      time: 0,
    }
  },
  mounted () {
    this.time = 5
    // 设定一个定时器，每一秒调用一次coundDown方法，time-1
    setInterval(this.countDown, 1000)
  },
  methods: {
    countDown () {
      this.time--
    },
  },
  watch: {
    time (val) {
      if (val === 0) {
        // this.$router.push('/user/nominalominalhome')
				this.$router.push('/login')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
a {
  color: #069;
}
</style>
