<template>
  <div>
    <LoginHeader></LoginHeader>
    <div class="line"></div>
    <ForgetStep :step="step" ></ForgetStep>
    <section class="container">
			 <div v-if="step === 2" class="tab-content">
        <ForgetStep2 @stepEvent="registerStep"></ForgetStep2>
      </div>
      <div v-else-if="step == 3" class="tab-content">
        <ForgetStep3 @stepEvent="registerStep"></ForgetStep3>
      </div>
      <div v-else class="tab-content">
        <ForgetStep1 @stepEvent="registerStep"></ForgetStep1>
      </div>

    </section>
    <AppFooter />
  </div>
</template>

<script>
import LoginHeader from '@/views/login/components/LoginHeader'
import AppFooter from '@/components/AppFooter'
import ForgetStep from '@/views/login/components/ForgetStep'
import ForgetStep1 from '@/views/login/components/ForgetStep1'
import ForgetStep2 from '@/views/login/components/ForgetStep2'
import ForgetStep3 from '@/views/login/components/ForgetStep3'

export default {
  name: 'Register',
  components: {
    LoginHeader,
    AppFooter,
    ForgetStep,
    ForgetStep1,
		ForgetStep2,
		ForgetStep3
  },
  data () {
    return {
      step: 1,
      from: {
        iphone: '',
        code: '',
        userName: '',
        pwd: '',
        rePwd: '',
      },
    }
  },
  methods: {

    registerStep (data) {
      this.step = data.num
      if (data.iphone) {
        this.from.iphone = data.iphone
        this.from.code = data.code
				localStorage.setItem('iphone', data.iphone)
      }
      if (data.userName) {
        this.from.userName = data.userName
        this.from.pwd = data.passwd
        this.from.rePwd = data.passwdCheck
      }

    },

  },
  setup () {
    // const route = useRoute();
    // const store = useStore();
    // const router = useRouter();

    return {}
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background: #fff;
  padding: 25px 0;
  position: relative;
  height: 730px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  .text {
    background: #fff;
    font-size: 16px;
    font-weight: bold;
    width: 60%;
  }
}

.header-step {
  background: #fff;
  width: 60%;
  left: 50%;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.line {
  height: 4px;
  background: #d12b23;
}

.tab {
  background: #fff;
  height: 80px;
  padding-top: 40px;
  font-size: 18px;
  text-align: center;

  a {
    color: #666;
    display: inline-block;
    width: 350px;
    line-height: 40px;
    border-bottom: 2px solid #e4e4e4;

    i {
      font-size: 22px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
      margin-left: 4px;
    }

    &.active {
      color: @xtxColor;
      border-color: @xtxColor;
    }
  }
}

.tab-content {
  width: 350px;
  min-height: 600px;
  background: #fff;
}
</style>
